import { Login, ForgotPassword } from './pages/Auth';
import { SignUp } from './pages';
import Pricing from './pages/Pricing/Pricing';
import Settings from './pages/Settings/Settings';
import Profile from './pages/Profile/Profile';
import User_Profile from './pages/User_Profile/User_Profile';
import Activities from './pages/Quiz/Activities/Activities';
import Home from './pages/Home/Home';
import DirectionChart from './pages/Quiz/DirectionChart';
import CountBySeven from './pages/Quiz/CountBySeven';
import BrainWarriorWorkout from './pages/Quiz/BrainWarriorWorkout';
import FingerSkipCounting from './pages/Quiz/FingerSkipCounting';
import CircleCounting from './pages/Quiz/CircleCounting';
import Figure8 from './pages/Quiz/Figure8';
import NumberFamilies from './pages/Quiz/NumberFamilies';
import ColourCoded from './pages/Quiz/ColourCoded';
import Pdf from './pages/Home/PDF/Pdf';
import NumberQuiz from './pages/Quiz/Number-Quiz';
import SpeedCounting from './pages/Quiz/SpeedCounting/SpeedCounting';
import Dashboard from './pages/Admin/DashBoard/index.js';
import StudentSummary from './pages/Admin/StudentSummary';
import StudentDailyUse from './pages/Admin/StudentDailyUse';
import Subscriptions from './pages/Admin/Subscriptions';
import Feedback from './pages/Admin/Feedback';
const user = JSON.parse(localStorage.getItem('user'));

const routes = [
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    isPrivate: false,
    layout: 'auth',
    isAdmin: false,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    isPrivate: false,
    layout: 'auth',
    isAdmin: false,
  },
  {
    path: '/forgotpassword/otp/:otp',
    name: 'forgotpassword',
    component: ForgotPassword,
    isPrivate: false,
    layout: 'auth',
    isAdmin: false,
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: ForgotPassword,
    isPrivate: false,
    layout: 'auth',
    isAdmin: false,
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    isPrivate: false,
    layout: 'public',
    isAdmin: false,
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: Pricing,
    isPrivate: false,
    layout: 'isChild',
    isAdmin: false,
  },
  {
    path: '/profile',
    name: 'profile',
    component: User_Profile,
    isPrivate: true,
    layout: 'layout',
    isSubcribe: true,
    isAdmin: false,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    isPrivate: true,
    layout: 'layout',
    isSubcribe: true,
    isAdmin: false,
  },
  {
    path: '/settings/:card',
    name: 'settings',
    component: Settings,
    isPrivate: true,
    layout: 'layout',
    isSubcribe: true,
    isAdmin: false,
  },
  {
    path: `/profile/edit/:id`,
    name: 'profile-edit',
    component: Profile,
    isPrivate: true,
    layout: 'layout',
    isSubcribe: true,
    isAdmin: false,
  },
  {
    path: `/summary`,
    name: `${user?.user_type === 'Parent' ? 'Child' : 'Student'} Summary`,
    icon: 'nc-icon nc-notes',
    component: StudentSummary,
    layout: 'layout',
    isPrivate: true,
    isSubcribe: true,
    isAdmin: false,
    isTeacherAndParent: true,
  },

  {
    path: `/daily_use`,
    name: `${user?.user_type === 'Parent' ? 'Child' : 'Student'} Daily Use`,
    icon: 'nc-icon nc-paper-2',
    component: StudentDailyUse,
    layout: 'layout',
    isPrivate: true,
    isSubcribe: true,
    isAdmin: false,
    isTeacherAndParent: true,
  },
  {
    path: `/activities`,
    name: 'quiz',
    component: Activities,
    isPrivate: true,
    layout: 'layout',
    isAdmin: false,
  },
  {
    path: `/activities/number-quiz`,
    name: 'quiz',
    component: NumberQuiz,
    isPrivate: true,
    layout: 'layout',
    isAdmin: false,
  },
  {
    path: `/activities/final-quiz`,
    name: 'quiz',
    component: NumberQuiz,
    isPrivate: true,
    layout: 'layout',
    isAdmin: false,
  },
  {
    path: `/activities/direction-chart`,
    name: 'quiz',
    component: DirectionChart,
    isPrivate: true,
    layout: 'layout',
    isAdmin: false,
  },
  {
    path: `/activities/figure8`,
    name: 'quiz',
    component: Figure8,
    isPrivate: true,
    layout: 'layout',
    isAdmin: false,
  },
  {
    path: `/activities/speed-counting`,
    name: 'quiz',
    component: SpeedCounting,
    isPrivate: true,
    layout: 'layout',
    isAdmin: false,
  },
  {
    path: `/activities/count-by-seven`,
    name: 'quiz',
    component: CountBySeven,
    isPrivate: true,
    layout: 'layout',
    isAdmin: false,
  },
  {
    path: `/activities/circle-counting`,
    name: 'quiz',
    component: CircleCounting,
    isPrivate: true,
    layout: 'layout',
    isAdmin: false,
  },
  {
    path: `/activities/brain-warrior-workout`,
    name: 'quiz',
    component: BrainWarriorWorkout,
    isPrivate: true,
    layout: 'layout',
    isAdmin: false,
  },
  {
    path: `/activities/finger-skip-counting`,
    name: 'quiz',
    component: FingerSkipCounting,
    isPrivate: true,
    layout: 'layout',
    isAdmin: false,
  },
  {
    path: `/activities/number-families`,
    name: 'quiz',
    component: NumberFamilies,
    isPrivate: true,
    layout: 'layout',
    isAdmin: false,
  },
  {
    path: `/activities/colour-coded`,
    name: 'quiz',
    component: ColourCoded,
    isPrivate: true,
    layout: 'layout',
    isAdmin: false,
  },
  {
    path: `/pdf`,
    name: 'pdf',
    component: Pdf,
    isPrivate: true,
    layout: 'isLogin',
    isAdmin: false,
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
    layout: 'admin',
    isPrivate: true,
    isSubcribe: true,
    isAdmin: true,
  },
  // {
  //   path: `/student_summary`,
  //   name: `${user?.user_type === 'Parent' ? 'Child' : 'Student'} Summary`,
  //   icon: 'nc-icon nc-notes',
  //   component: StudentSummary,
  //   layout: 'admin',
  //   isPrivate: true,
  //   isSubcribe: true,
  //   isAdmin: true,
  // },
  // {
  //   path: `/student_daily_use`,
  //   name: `${user?.user_type === 'Parent' ? 'Child' : 'Student'} Daily Use`,
  //   icon: 'nc-icon nc-paper-2',
  //   component: StudentDailyUse,
  //   layout: 'admin',
  //   isPrivate: true,
  //   isSubcribe: true,
  //   isAdmin: true,
  // },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    icon: 'nc-icon nc-money-coins',
    component: Subscriptions,
    layout: 'admin',
    isPrivate: true,
    isSubcribe: true,
    isAdmin: true,
  },
  {
    path: '/feedback',
    name: 'Feedback',
    icon: 'far fa-comment',
    component: Feedback,
    layout: 'admin',
    isPrivate: true,
    isSubcribe: true,
    isAdmin: true,
  },
];

export default routes;
