import Types from '../types/FeedbackTypes';
import { getFeedbacks } from '../../api/feedbackApi';
import { notification } from 'antd';

export const getFeedbacksApi = () => {
  return async (dispatch) => {
    dispatch({
      type: Types.GET_FEEDBACKS,
    });

    try {
      const resp = await getFeedbacks();
      // console.log('API Response:', resp);

      if (resp.status === 1) {
        const feedbackArray = Object.values(resp).filter((item) => typeof item === 'object');
        // console.log('Transformed feedback array:', feedbackArray);

        dispatch({
          type: Types.SUCCESS_GET_FEEDBACKS,
          payload: feedbackArray,
        });
        // console.log('Dispatched feedbacks:', feedbackArray);
      } else {
        // console.error('Failed to fetch feedbacks:', resp.message);
        notification.error({
          message: resp?.message?.message || 'Failed to load feedbacks',
        });
        dispatch({
          type: Types.FAILURE_GET_FEEDBACKS,
          status: resp.status,
          message: resp.message,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error fetching feedbacks',
      });
      dispatch({
        type: Types.FAILURE_GET_FEEDBACKS,
        message: error.message,
      });
    }
  };
};
