import Types from '../types/FeedbackTypes';

const initialState = {
  loading: false,
  message: '',
  status: '',
  feedbacks: [], 
};

export default function FeedbackReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_FEEDBACKS:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_GET_FEEDBACKS:
      return {
        ...state,
        loading: false,
        feedbacks: action.payload, 
      };
    case Types.FAILURE_GET_FEEDBACKS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
}