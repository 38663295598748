import React, { useEffect, useState } from 'react';
import styles from './Footer.module.scss';
import CustomButton from '../../../components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { editCurrentProfile } from '../../../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { setTermService } from '../../../store/actions/subscriptionActions';
import { privacyTerms } from '../../../helpers/constants';

const Footer = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const termServiceData = useSelector((state) => state?.subscription?.termServiceData);
  const currentUser = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    if (checkPrivacyTerms() && params.card !== 'feedback' && params.card !== 'change-password') {
      handleSubmit();
    }
  }, [termServiceData, params.card]);

  const checkPrivacyTerms = () => {
    return privacyTerms.every((term) => termServiceData?.includes(term));
  };
  const handleSubmit = async () => {
    const userData = {
      email: currentUser?.email,
      id: currentUser?.id,
      name: currentUser?.name,
      user_type: currentUser?.user_type,
      is_terms_agreed: true,
    };
    await dispatch(editCurrentProfile(currentUser?.id, userData, () => {}));
    setTimeout(() => {
      navigate('/pricing', { state: props.state });
    }, 1000);
  };
  const renderButtons = () => {
    switch (params.card) {
      case 'terms':
        return (
          <>
            <CustomButton
              onClick={() => {
                navigate('/pricing');
              }}
            >
              Disagree
            </CustomButton>
            <CustomButton
              type="primary"
              onClick={() => {
                dispatch(setTermService('terms'));
                !termServiceData?.includes('privacy') && navigate('/settings/privacy', { state: props.state });
              }}
            >
              Agree
            </CustomButton>
          </>
        );
      case 'privacy':
        return (
          <>
            <CustomButton
              onClick={() => {
                navigate('/pricing');
              }}
            >
              Disagree
            </CustomButton>
            <CustomButton
              type="primary"
              onClick={() => {
                dispatch(setTermService('privacy'));
                !termServiceData?.includes('terms') && navigate('/settings/terms');
              }}
            >
              Agree
            </CustomButton>
          </>
        );
      case 'feedback':
        return (
          <>
            <CustomButton type="primary" htmlType="submit">
              Submit
            </CustomButton>
          </>
        );
      case 'change-password':
        return (
          <>
            <CustomButton type="primary" htmlType="submit">
              Change password
            </CustomButton>
          </>
        );
      default:
        return (
          <>
            <CustomButton>Disagree</CustomButton>
            <CustomButton type="primary">Agree</CustomButton>
          </>
        );
    }
  };

  return <div className={styles.buttonClass}>{renderButtons()}</div>;
};

export default Footer;
