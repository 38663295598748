import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Skeleton, Select } from 'antd';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { FilterOutlined } from '@ant-design/icons'
// Components
import LineChart from '../../../components/Charts/LineChart';
import BarChart from '../../../components/Charts/BarChart';
import PieChart from '../../../components/Charts/PieChart';
import FilterButton from '../../../components/FilterButton';
import ChildLineChart from '../../../components/ChildCharts/LineChart';
import ChildBarChart from '../../../components/ChildCharts/BarChart';
import ChildPieChart from '../../../components/ChildCharts/PieChart';
import { getTimeWithSeconds } from '../../../helpers/utility';
import CustomButton from '../../../components/Button/Button';
import styles from '../Subscriptions/Subscriptions.module.scss'
// Actions
import { summaryChartApi, getAdminSubscriptions } from '../../../store/actions/adminActions';

// Hooks
import { useReducerData } from '../../../store/hooks';

//utils function
import { minsToTime } from '../../../helpers/utility'
import styled from 'styled-components';


function Dashboard() {
  const [studentName, setStudentName] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();
  const summaryChart = useReducerData('admin', 'summaryChart');
  const loading = useReducerData('admin', 'loadingChart');

  useEffect(() => {
    dispatch(getAdminSubscriptions());
  }, []);

  const tabs = ['Subscriptions Chart', 'Students Summary Chart'];

  const convertData = (dataType) => {
    return dataType && dataType?.map((item) => item);
  };

  const option = {
    title: {
      text: 'Student Summary Line Chart',
    },
    grid: {
      left: '6%',
      right: '4%',
      bottom: '7%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: summaryChart?.filtered_data?.student_list,
      name: 'Student Name',
      nameLocation: 'middle',
      nameGap: 30,
    },
    yAxis: {
      type: 'value',
      name: 'Total Time on App',
      nameLocation: 'middle',
      nameGap: 65,
      axisLabel: {
        formatter: '{value} mins',
      },
    },
    series: {
      type: 'line',
      data: convertData(summaryChart?.filtered_data?.time_list),
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return getTimeWithSeconds(params)
      },
    },
  };

  const onTabChange = (e) => {
    if (e === tabs[1]) {
      setShowFilter(true)
      dispatch(summaryChartApi());
    } else {
      setShowFilter(false)
      dispatch(getAdminSubscriptions());
    }
  };

  const loadingChart = () => {
    return <Skeleton active paragraph={{ rows: 4 }} />;
  };

  const studentChart = () => {
    return (
      <Row>
        <Col md="6">
          <Card>
            <Card.Body>
              <ChildLineChart option={option} />
            </Card.Body>
          </Card>
        </Col>

        <Col md="6">
          <Card>
            <Card.Body>
              <ChildBarChart title={'Student Summary Bar Chart'} />
            </Card.Body>
          </Card>
        </Col>

        <Col md="12">
          <Card>
            <Card.Body>
              <ChildPieChart title={'Student Summary Pie Chart'} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  };

  const subscriptionChart = () => {
    return (
      <>
        <Row>
          <Col md="6">
            <Card>
              <Card.Body>
                <LineChart />
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <Card.Body>
                <BarChart />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <PieChart />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  const notFound = () => {
    return (
      <Col md="12">
        <Card>
          <Card.Body>
            <div className="text-center">
              <span>No data found </span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  };
  const FilterApply = (item) => {
      dispatch(summaryChartApi(item))
  }


  const options = summaryChart?.data?.student_list?.map((student, index) => ({
    label: student,
    value: index
  }));

  const clearValue = (item) => {
    if(item === undefined){
      dispatch(summaryChartApi(''))
    }
  }

  return (
    <>
      <Container fluid>
        {showFilter ? (
          <Row className="d-flex justify-content-end mb-3">
            <ClearData className={`${styles.clearData} d-flex justify-content-end text-start`}>
              <Select
                showSearch
                allowClear
                style={{ width: 300, borderRadius: '15px' }}
                suffixIcon={<FilterOutlined />}
                placeholder="Search Student"
                optionFilterProp="children"
                filterOption={(input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                 options={options}
                onSelect={(_, value) => { FilterApply(value?.label) }}
                onChange={(value) => {clearValue(value)}}
              />
            </ClearData>
          </Row>) : ''}
        <Tabs
          type="card"
          onChange={onTabChange}
          items={tabs.map((item, i) => {
            return {
              label: item,
              key: item,
              children: loading
                ? loadingChart()
                : i === 0
                  ? subscriptionChart()
                  : i === 1
                    ? studentChart()
                    : notFound(),
            };
          })}
        />
      </Container>
    </>
  );
}

export default Dashboard;


const ClearData = styled(Col)`
.ant-select-clear{
  top: 45% !important;
}
`