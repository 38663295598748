import Types from '../types/AuthTypes';
import AuthReducer from './AuthReducer';
import QuizReducer from './QuizReducer';
import SubscriptionReducer from './SubscriptionReducer';
import AdminReducer from './AdminReducer';
import FeedbackReducer from './FeedbackReducer';

import { combineReducers } from 'redux';

const reducers = {
  auth: AuthReducer,
  quiz: QuizReducer,
  subscription: SubscriptionReducer,
  admin: AdminReducer,
  feedback: FeedbackReducer,
};

const combinedReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === Types.LOGOUT_SUCCESS) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
