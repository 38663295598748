import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select, Tooltip, Modal } from 'antd';
import { Container, Row } from 'react-bootstrap';
import CustomTable from '../../../components/CustomTable';
import { getFeedbacksApi } from '../../../store/actions/feedbackActions';
import { useReducerData } from '../../../store/hooks';
import styles from './Feedback.module.scss';
import styled from 'styled-components';

function Feedback() {
  const dispatch = useDispatch();
  const loading = useReducerData('feedback', 'loading');
  const data = useReducerData('feedback', 'feedbacks');

  const [onloadState, setOnloadState] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState({ open: false, data: {} });

  useEffect(() => {
    const fetchData = { page: 1 };
    dispatch(getFeedbacksApi(fetchData));
    setOnloadState(true);
  }, [dispatch]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      render: (email) => <Tooltip title={email}>{email}</Tooltip>,
    },
    {
      title: 'User Feedback',
      width: 250,
      render: (_, record) => {
        const feedback = record.text;
        const isLongFeedback = feedback.length > 20;

        return (
          <div className={styles.feedback}>
            {isLongFeedback ? `${feedback.slice(0, 20)}...` : feedback}

            {isLongFeedback && (
              <a className={styles.showMoreLink} onClick={() => setIsModalOpen({ open: true, data: record })}>
                Show More
              </a>
            )}
          </div>
        );
      },
    },
  ];

  const handleCancel = () => {
    setIsModalOpen({ open: false, data: {} });
  };

  const tableData = () => {
    if (!data) return [];

    return Object.values(data)
      .filter((item) => typeof item === 'object')
      .map((item) => ({
        key: item.id,
        name: item?.name || '-',
        email: item?.email || '-',
        text: item?.text || 'No feedback',
      }));
  };

  const nextPagination = (page) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setPageNumber(page);
    const newData = { page };
    dispatch(getFeedbacksApi(newData));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div>
            <CustomTable
              columns={columns}
              data={tableData() || []}
              loading={loading}
              onloadState={onloadState}
              pageNumber={pageNumber}
              nextPagination={nextPagination}
              totalPages={data?.total_pages || 0}
            />
          </div>
        </Row>
      </Container>

      <StyledModal footer={null} title='Feedback' open={isModalOpen.open} onOk={handleCancel} onCancel={handleCancel}>
        {isModalOpen?.data?.text ? (
          <>
            {isModalOpen?.data?.text.length <= 20 ? (
              <p className={styles.questionStyle}>{isModalOpen.data?.title || 'No Title'}</p>
            ) : null}
            <p>{isModalOpen.data?.text}</p>
          </>
        ) : (
          'No feedback available'
        )}
      </StyledModal>
    </>
  );
}

export default Feedback;

const StyledModal = styled(Modal)`
  .ant-modal-title {
    font-size: 22px;
    font-weight: 700;
  }
  .ant-modal-body {
    font-size: 16px;
  }
`;
