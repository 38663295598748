import React from 'react';
import { useSelector } from 'react-redux';
import ReactECharts from 'echarts-for-react';

const PieChart = () => {
  const data = useSelector((state) => state.admin.subscriptionsGraph);
  const result = Object.entries(data)?.filter(([key]) => key !== 'status').map(([key, value]) => ({ name: value?.region, value: value?.subscriber }));

    function getRandomColor() {
      var letters = '0123456789ABCDEF'.split('');
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.round(Math.random() * 15)];
      }
      return color;
    }

  const option = {
    title: {
      text: 'Subscription Pie Chart',
    },
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '5%',
      left: 'center',
    },
    series: [
      {
        name: 'Subscriber',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: result,
        color: Array.from({ length: result.length }, () => getRandomColor())
      },
    ],
  };

  return (
    <>
      <ReactECharts option={option} style={{ height: 500 }} />
    </>
  );
};
export default PieChart;
